import { initializeFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { useFirebaseApp, AuthProvider, FirestoreProvider, useInitFirestore } from 'reactfire';
import { getAuth } from 'firebase/auth';
// theme
import { CircularProgress } from '@mui/material';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { AuthWrapper } from './utils/auth';

// ----------------------------------------------------------------------

export default function App() {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const { status, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {});
    await enableIndexedDbPersistence(db);
    return db;
  });

  if (status === 'loading') {
    return <CircularProgress />;
  }

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <AuthWrapper />
        </ThemeConfig>
      </FirestoreProvider>
    </AuthProvider>
  );
}
