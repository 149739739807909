// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { useUser, useFirestore, useFirestoreCollectionData, useFunctions } from 'reactfire';
import { collection } from 'firebase/firestore';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { data: user } = useUser();
  const firestore = useFirestore();
  const path = `users/${user.uid}/collections`;
  const ref = collection(firestore, path);
  const { status, data } = useFirestoreCollectionData(ref);
  const name = user ? user.displayName.split(' ')[0] : '';

  return (
    <Page title="Dashboard | Minimal-UI">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome back, {name}!</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates collections={data} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
