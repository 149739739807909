import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/logo.png"
      sx={{ width: 40, height: 40, ...sx }}
      style={logoStyles}
    />
  );
}

const logoStyles = {
  margin: 20
};
