import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, IconButton, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
// icons
import TwitterIcon from '@mui/icons-material/Twitter';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LanguageIcon from '@mui/icons-material/Language';
import StorefrontIcon from '@mui/icons-material/Storefront';
// utils
import Label from '../../Label';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, image_url, url, twitter_username, discord_url, external_url, stats } = product;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          variant="filled"
          color="info"
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: 'absolute',
            textTransform: 'uppercase'
          }}
        >
          {`${stats.floor_price} Ξ`}
        </Label>
        <ProductImgStyle alt={name} src={image_url} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to={url} color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {twitter_username && (
            <IconButton
              href={`https://www.twitter.com/${twitter_username}`}
              aria-label="delete"
              size="large"
            >
              <TwitterIcon />
            </IconButton>
          )}
          {discord_url && (
            <IconButton href={discord_url} aria-label="delete" size="large">
              <HeadsetMicIcon />
            </IconButton>
          )}
          {url && (
            <IconButton href={url} aria-label="delete" size="large">
              <StorefrontIcon />
            </IconButton>
          )}
          {external_url && (
            <IconButton href={external_url} aria-label="delete" size="large">
              <LanguageIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
