// scroll bar
import { FirebaseAppProvider, FirestoreProvider } from 'reactfire';
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const firebaseConfig = {
  apiKey: 'AIzaSyBNW2dlgOcr5ND7JAxGmOkE_vC3jULjQEo',
  authDomain: 'braunch-300aa.firebaseapp.com',
  projectId: 'braunch-300aa',
  storageBucket: 'braunch-300aa.appspot.com',
  messagingSenderId: '673172523932',
  appId: '1:673172523932:web:38ba4a0a34ae80c86bd659',
  measurementId: 'G-E7CNB9TQP3'
};

ReactDOM.render(
  <HelmetProvider>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FirebaseAppProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
