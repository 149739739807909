import PropTypes from 'prop-types';
import { useUser, useFirestore } from 'reactfire';
import { addDoc, collection } from 'firebase/firestore';
import React from 'react';
// material
import { Box, Card, Typography, Stack, TextField, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// icons
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// utils
import Label from '../../Label';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

function buttonActive(url) {
  return url.length < 23 && !url.includes('http');
}

export default function ShopProductCard() {
  // TODO add validation
  const [url, setURL] = React.useState('');
  const { data: user } = useUser();
  const firestore = useFirestore();

  const handleChange = (event) => {
    setURL(event.target.value);
  };
  const handleClick = async (event) => {
    const path = `users/${user.uid}/collections`;
    const ref = collection(firestore, path);
    const res = await addDoc(ref, { url });
    if (res.id) {
      setURL('');
    }
  };
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ProductImgStyle alt="green" src="/static/opensea.png" />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle2" noWrap>
          Add Tracked Opensea Collection
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <TextField
            id="outlined-basic"
            label="Collection URL"
            variant="outlined"
            size="small"
            value={url}
            onChange={handleChange}
          />
          <IconButton
            aria-label="delete"
            size="large"
            disabled={buttonActive(url)}
            onClick={handleClick}
          >
            <AssignmentTurnedInIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}
