import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useUser, useFirestore, useFirestoreCollectionData, useFunctions } from 'reactfire';
import { collection } from 'firebase/firestore';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_DATA = [{ data: [40, 43, 44, 47, 54, 58, 69, 110, 120, 138] }];

export default function AppConversionRates(collections) {
  const { data: user } = useUser();
  const firestore = useFirestore();
  const path = `users/${user.uid}/collections`;
  const ref = collection(firestore, path);
  const { status, data } = useFirestoreCollectionData(ref);

  const categories = data ? data.map((collection) => collection.name) : [];
  const chartData = data ? data.map((collection) => calculateHotness(collection)) : [];

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: { categories }
  });

  return (
    <Card>
      <CardHeader title="🔥 Hot Collections" subheader="Quantative measure of excitement." />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={[{ data: chartData }]}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}

function calculateHotness(collection) {
  const { name, image_url, url, twitter_username, discord_url, external_url, stats } = collection;
  const {
    floor_price,
    one_day_change,
    thirty_day_sales,
    one_day_sales,
    thirty_day_average_price,
    one_day_average_price
  } = stats;

  const change_factor = one_day_change / one_day_average_price;
  const floor_factor = floor_price / thirty_day_average_price;
  const sales_factor = (30 * one_day_sales) / thirty_day_sales;

  return change_factor + floor_factor + sales_factor;
}
