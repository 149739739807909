import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useSigninCheck, useUser } from 'reactfire';
import { CircularProgress } from '@mui/material';
import Login from '../pages/Login';
import Router from '../routes';

export const signOut = (auth) => auth.signOut().then(() => console.log('signed out'));
export const signIn = async (auth) => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
};

export const AuthWrapper = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const { data: user } = useUser();

  if (status === 'loading') {
    return <CircularProgress />;
  } else if (signInCheckResult.signedIn === true && user) {
    console.log('rendering router');
    return <Router />;
  } else {
    console.log('rendering login');
    return <Login />;
  }
};
