import { useAuth } from 'reactfire';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
// material
import { Stack, Button, Divider, Typography } from '@mui/material';
import { signIn } from '../../utils/auth';

// ----------------------------------------------------------------------

export default function AuthSocial() {
  const auth = useAuth();

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={() => signIn(auth)}
        >
          <Icon icon={googleFill} color="#DF3E30" height={24} />
        </Button>
      </Stack>
    </>
  );
}
